<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addPasswordResetTokensModal' hide-footer>
        <addPasswordResetTokens @closeAddPasswordResetTokens='toggleAddPasswordResetTokens()' @addPasswordResetTokensSuccess='addPasswordResetTokensSuccess()'></addPasswordResetTokens>
      </b-modal>
      <b-modal ref='editPasswordResetTokensModal' hide-footer>
        <editPasswordResetTokens
          :editingPasswordResetTokens='currentPasswordResetTokens'
          @closeEditPasswordResetTokens='toggleEditPasswordResetTokens()'
          @editPasswordResetTokensSuccess='editPasswordResetTokensSuccess()'
        ></editPasswordResetTokens>
      </b-modal>
      <div class='row'>
        <h2>PasswordResetTokens</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddPasswordResetTokens()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addPasswordResetTokens from '../../components/addPasswordResetTokens';
import editPasswordResetTokens from '../../components/editPasswordResetTokens';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'UserId',
                field: 'UserId',
                sort: 'asc',
              },
              {
                label: 'Token',
                field: 'Token',
                sort: 'asc',
              },
              {
                label: 'TokenExpiry',
                field: 'TokenExpiry',
                sort: 'asc',
              },
              {
                label: 'Redeemed',
                field: 'Redeemed',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addPasswordResetTokensOpen: false,
      editPasswordResetTokensOpen: false,
      currentPasswordResetTokens: {}
    };
  },
  components: {
    addPasswordResetTokens,
    editPasswordResetTokens,
    Datatable,
  },
  created: function() {
    this.getPasswordResetTokens();
    this.addPasswordResetTokensOpen = false;
    this.editPasswordResetTokensOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getPasswordResetTokens() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getPasswordResetTokens', payload);
    },
    editItem(PasswordResetTokens) {
      this.toggleEditPasswordResetTokens();
      this.currentPasswordResetTokens = PasswordResetTokens;
    },
    toggleAddPasswordResetTokens() {
      if(this.addPasswordResetTokensOpen)
      {
        this.$refs.addPasswordResetTokensModal.hide()
      }
      else{
        this.$refs.addPasswordResetTokensModal.show()
      }
      this.addPasswordResetTokensOpen = !this.addPasswordResetTokensOpen;
    },
    addPasswordResetTokensSuccess() {
      this.toggleAddPasswordResetTokens();
      miniToastr['success']('PasswordResetTokens Added', 'Success', 1000, null);
      this.getPasswordResetTokens();
    },
    toggleEditPasswordResetTokens() {
      if(this.editPasswordResetTokensOpen)
      {
        this.$refs.editPasswordResetTokensModal.hide()
      }
      else{
        this.$refs.editPasswordResetTokensModal.show()
      }
      this.editPasswordResetTokensOpen = !this.editPasswordResetTokensOpen;
    },
    editPasswordResetTokensSuccess() {
      this.toggleEditPasswordResetTokens();
      miniToastr['success']('PasswordResetTokens Edited', 'Success', 1000, null);
      this.getPasswordResetTokens();
    }
  }
};
</script>
<style scoped>
.PasswordResetTokensThumbnail {
  height: 50px;
}
</style>
